import React, { Component } from "react"
import { Link } from "gatsby"

import LogoImage from "../../../assets/images/logos/ibx-logo.png"
import LogoImageNeg from "../../../assets/images/logos/ibx-logo-neg.png"

import "./header.scss"

export default class Header extends Component {
    state = {
        siteTitle: ``,
        hasScrolled: false,
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll)
    }

    handleScroll = event => {
        const scrollTop = window.pageYOffset

        if (scrollTop > 36) {
            this.setState({ hasScrolled: true })
        } else {
            this.setState({ hasScrolled: false })
        }

    }

    render() {
        const { siteTitle, hasScrolled } = this.state

        return (
            <header className={`header ${hasScrolled ? "header-scrolled" : ""}`}>
                <nav className={"header__nav"}>
                    <input type="checkbox" className={"header__nav-button"} id="headerNavbarButton" />
                    <label htmlFor={"headerNavbarButton"} className={"header__nav-icon"}>
                        <div className={"header__nav-spinner diagonal part-1"}></div>
                        <div className={"header__nav-spinner horizontal"}></div>
                        <div className={"header__nav-spinner diagonal part-2"}></div>
                    </label>

                    <div id="headerSidebarMenu">
                        <ul className={"header__nav-menu"}>
                            <li><Link to="/marketing-digital">Marketing Digital</Link></li>
                            <li><Link to="/diseno-grafico">Diseño Gráfico</Link></li>
                            <li><Link to="/produccion-grafica">Producción Gráfica</Link></li>
                            <li><Link to="/productora-audiovisual">Productora Audiovisual</Link></li>
                            <li><a href="/blog" title="Blog" target="_blank" rel="noreferrer">Blog</a></li>
                            <li><Link to="/contacto">Contacta</Link></li>
                        </ul>
                    </div>
                    <a className={"header__navbar-brand"} href="/" title={siteTitle}>
                        <img className={"header__navbar-brand-img-neg"} src={LogoImageNeg} alt={siteTitle} />
                        <img className={"header__navbar-brand-img"} src={LogoImage} alt={siteTitle} />
                    </a>
                    <ul className={"header__navbar-contact"}>
                        <li><a className="header__navbar-contact-link phone-icon" href="tel:685677031" title="Llámanos"><span>685 677 031</span></a></li>
                        <li className="hidden-mobile"><Link className="header__navbar-contact-link email-icon" to="/contacto" title="Pide presupuesto"><span>Pide presupuesto</span></Link></li>
                    </ul>

                </nav>
            </header>
        )
    }

}
