import { Link } from "gatsby"
import React from "react"

import "./footer.scss"

import LogoImageNeg from "../../../assets/images/logos/ibx-logo-neg.png"

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-3">
                        <img className="footer__logo" src={LogoImageNeg} alt="ibx | Agencia Marketing Digital" />
                        <ul className="footer__links-list">
                            <li><a className="footer__link-item" href="mailto:info@ibxagency.com">info@ibxagency.com</a></li>
                            <li><a className="footer__link-item" href="tel:685 677 031">685 677 031</a></li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-3">
                        <h2 className="footer__title">Sobre nosotros</h2>
                        <ul className="footer__links-list">
                            <li><Link className="footer__link-item" to="/quienes-somos">Quiénes somos</Link></li>
                            <li><Link className="footer__link-item" to="/contacto">Contacto</Link></li>
                            <li><Link className="footer__link-item" to="/sitemap">Sitemap</Link></li>
                            <li><a className="footer__link-item" href="/blog" target="_blank" rel="noreferrer">Blog</a></li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-3">
                        <h2 className="footer__title">Servicios</h2>
                        <ul className="footer__links-list">
                            <li><Link className="footer__link-item" to="/marketing-digital">Marketing digital</Link></li>
                            <li><Link className="footer__link-item" to="/diseno-grafico">Diseño gráfico</Link></li>
                            <li><Link className="footer__link-item" to="/produccion-grafica">Producción gráfica</Link></li>
                            <li><Link className="footer__link-item" to="/productora-audiovisual">Productora audiovisual</Link></li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-3">
                        <h2 className="footer__title">Redes Sociales</h2>
                        <ul className="footer__links-list">
                            <li><a className="footer__link-item" href="https://www.facebook.com/ibxagency" title="Facebook" target="_blank" rel="noreferrer">Facebook</a></li>
                            <li><a className="footer__link-item" href="https://twitter.com/ibxagency" title="Twitter" target="_blank" rel="noreferrer">Twitter</a></li>
                            <li><a className="footer__link-item" href="https://www.linkedin.com/company/ibxagency" title="LinkedIn" target="_blank" rel="noreferrer">LinkedIn</a></li>
                            <li><a className="footer__link-item" href="https://www.instagram.com/ibxagency/" title="Instagram" target="_blank" rel="noreferrer">Instagram</a></li>
                        </ul>
                    </div>
                </div>
                <hr className="footer__hr" />
                <div className="row footer__bottom">
                    <div className="col-12 col-md-6">
                        <Link className="footer__link-item" to="/aviso-legal">Aviso legal</Link>
                        <Link className="footer__link-item" to="/politica-privacidad">Política privavidad</Link>
                    </div>
                    <div className="col-12 col-md-6 text-right">
                        © {new Date().getFullYear()}, <Link className="footer__link-item" to="/">ibx Agency</Link>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer